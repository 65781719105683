.main-page {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  align-items: center;
  background: #111111;

  .header {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 80px;

    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 51.88%, rgb(17, 17, 17) 83%),
      url('https://lolstatic-a.akamaihd.net/riot-kr/legal/header.jpg') no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 613px;

    @media screen and (max-width: 768px) {
      background-size: auto 322px;
      height: 322px;
    }

    .title {
      padding: 0 0.3em;
      font-family: 'RixSGoB';
      font-size: 80px;
      line-height: 125%;
      letter-spacing: -0.02em;
      color: #f0e6d2;

      @media screen and (max-width: 768px) {
        font-size: 28px;
      }
    }
  }

  .categories {
    display: block;
    font-family: RixSGoB;
    margin-bottom: 100px;

    .category {
      margin-top: 62px;
      @media screen and (max-width: 768px) {
        margin-top: 30px;
      }

      .section {
        display: flex;
        align-items: center;
        width: 880px;
        padding: 10px;
        border-bottom: 1px solid #403f3f;
        word-break: keep-all;
        word-wrap: break-word;

        @media screen and (max-width: 768px) {
          width: 90%;
        }

        .logo {
          display: inline-block;
        }

        .title {
          display: inline-block;
          font-size: 30px;
          color: #d0a85c;
          padding: 0 0 0px 13px;

          @media screen and (max-width: 768px) {
            font-size: 24px;
          }
        }

        @media screen and (max-width: 768px) {
          padding-left: 20px;
        }
      }

      .nav {
        font-size: 24px;
        color: white;
        margin-top: 20px;
        padding: 0 0 0px 43px;
        line-height: 150%;
        word-break: keep-all;
        word-wrap: break-word;

        @media screen and (max-width: 768px) {
          font-size: 18px;
          padding: 0 10px 0px 63px;
          margin-top: 8px;
        }

        a {
          text-decoration: none;
          color: white;
          cursor: pointer;
        }

        a:hover {
          color: rgba(208, 168, 92, 0.5);
        }
      }
    }
  }
}
