.App {
  display: block;
  min-height: 50vh;
  color: rgb(10, 10, 10);
  background: #f7f7f7;

  .footer {
    display: block;
    text-align: center;
    padding: 130px 0;
    background: #111111;
  }
}
